<template>
    <div>
         <b-card>
			<b-row>					
				<b-col cols="12" class="mb-1">
					<h5>FILTROS</h5>
				</b-col>
				<b-col sm="12" md="4">
					<label>Estado(s) de formulario</label>
					<v-select 
                        multiple
					    v-model="filterState"
					    :options="optionsStates" />
				</b-col>
                <b-col sm="12" md="4">
                    <label>Fecha de inicio</label>
                     <flat-pickr
                        v-model="filterStartDate"
                        class="form-control"
                        :config="config"
                    />
                </b-col>
                <b-col sm="12" md="4">
                    <label>Fecha límite</label>
                     <flat-pickr
                        v-model="filterEndDate"
                        class="form-control"
                        :config="config"
                    />
                </b-col>
            </b-row>
		</b-card>

        <!-- Table Container Card -->
        <b-card
            no-body
            class="mb-0">

            <div class="m-2">

                <!-- Table Top -->
                <b-row>

                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Mostrar</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label>registros</label>
                    </b-col>  

                    <!-- Search -->
                    <b-col
                        cols="12"
                        md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block"
                                placeholder="Buscar ..."
                            />
                        </div>
                    </b-col>                  

                </b-row>

            </div>
            
            <b-table
                ref="refFormListTable"
                class="position-relative"
                :style="totalUsers < 3 && totalUsers > 0 ? 'height: 280px' : 'height: inherit'"
                :items="fetchForms"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros coincidentes"
                :sort-desc.sync="isSortDirDesc">

                <template #cell(month_and_year)="data">
                    {{ getDateFormat(data.item.start_date, true) }}                
                </template>

                <template #cell(state)="data">           
                    <b-badge :variant="`light-${data.item.state.color}`">
                        {{data.item.state.name}}
                    </b-badge>                  
                </template>
                
                <template #cell(start_date)="data">
                    <b-col style="width: 200px">
                        {{ getDateFormat(data.item.start_date) }} 00:00:00 
                    </b-col>   
                </template>
                
                <template #cell(date_dispatch_review)="data">
                    <b-col style="width: 250px">
                        {{ getDateFormat(data.item.date_dispatch_review, false, true) }}
                     </b-col> 
                </template>

                <template #cell(options)="data">
                    <b-button
                        block
                        v-ripple.400="'rgba(255, 159, 67, 0.15)'"                                     
                        :to="{ name: 'reviser-form-edit', params: { id: data.item.id }, query: { form: data.item}}"                               
                        :variant="`outline-${getButtonState(data.item.state.id).variant}`"   
                        :disabled="[States.EN_REVISION, States.COMPLETADO].includes(data.item.state.id) ? false : true"                                               
                    >
                        <feather-icon :icon="getButtonState(data.item.state.id).icon" />
                        {{ getButtonState(data.item.state.id).text }}
                    </b-button>
                </template>

                <template #cell(report_download)="data">
                    <b-dropdown
                        variant="link"
                        no-caret
                        :right="$store.state.appConfig.isRTL">
                        
                        <template #button-content>
                            <feather-icon
                                icon="MoreVerticalIcon"
                                size="16"
                                class="align-middle text-body"
                            />
                        </template>

                        <b-dropdown-item 
                            v-if="data.item.report_storage && data.item.state.id === States.COMPLETADO" 
                            @click='fileSrc(data.item.report_storage)'
                        >                             
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">
                                Descargar Informe
                            </span>
                        </b-dropdown-item>

                        <b-dropdown-item 
                            v-if="data.item.memo_code && data.item.state.id === States.COMPLETADO && data.item.memo_storage" 
                            @click='fileSrc(data.item.memo_storage)'
                        >   
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">
                                Descargar Memorándum
                            </span>
                        </b-dropdown-item> 
                        
                        <b-dropdown-item 
                            v-if="data.item.memo_tariff_storage && data.item.state.id === States.COMPLETADO"
                            @click='fileSrc(data.item.memo_tariff_storage)'
                        >   
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">
                                Descargar Memorándum de Tarifario
                            </span>
                        </b-dropdown-item>

                        <b-dropdown-item 
                            v-if="data.item.report_daily_wages_storage && data.item.state.id === States.COMPLETADO"
                            @click='fileSrc(data.item.report_daily_wages_storage)'
                        >   
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">
                                Descargar Reporte de Tarifario
                            </span>
                        </b-dropdown-item>

                        <b-dropdown-item 
                            v-if="data.item.report_labor_criticality_storage && data.item.state.id === States.COMPLETADO" 
                            @click='fileSrc(data.item.report_labor_criticality_storage)'
                        >                             
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">
                                Descargar Criticidad Laboral
                            </span>
                        </b-dropdown-item>

                        <b-dropdown-item 
                            v-if="data.item.state.id === States.COMPLETADO" 
                            @click='downloadSupportJobCriticality(data.item)'
                        >                             
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">
                                Descargar Sustentos Criticidad Laboral
                            </span>
                        </b-dropdown-item>

                        <b-dropdown-item 
                            v-if="data.item.report_observations_storage && data.item.state.name == 'COMPLETADO'" 
                            @click='fileSrc(data.item.report_observations_storage)'
                        >                             
                            <feather-icon icon="DownloadIcon" />
                            <span class="align-middle ml-50">
                                Descargar Reporte de Observaciones
                            </span>
                        </b-dropdown-item>

                    </b-dropdown>                    
                </template> 

            </b-table>
        
            <div class="mx-2 mb-2">
                <b-row>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Mostrando {{ dataMeta.from }} al {{ dataMeta.to }} de {{ dataMeta.of }} registros</span>
                    </b-col>

                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">

                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalUsers"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item">

                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="18"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="18"
                                />
                            </template>
                        </b-pagination>

                    </b-col>

                </b-row>
            </div>
        </b-card>

        <b-modal
            ref="modal-date-dispatch-review"
            cancel-variant="outline-secondary"
            ok-title="ACTUALIZAR"
            cancel-title="CANCELAR"
            title="ACTUALIZAR FECHA DE EVALUACIÓN"
            @ok="onSubmit"
            centered
            title-tag="div"
            header-bg-variant="primary"
            header-text-variant="white"
            no-close-on-backdrop
        >
            <b-form>
                <b-form-group class="mb-0">
                    <label for="date_dispatch_review">Fecha de evaluación</label>
                    <flat-pickr
                        id="date_dispatch_review"
                        v-model="dateNtim"
                        class="form-control"
                        :config="config2"
                    />
                </b-form-group>
            </b-form>
        </b-modal>
             
    </div>
</template>

<script>

    import { VBTooltip, VBModal } from 'bootstrap-vue';
    import { onUnmounted, reactive } from '@vue/composition-api';
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
    import { States } from '@core/utils/data'
    import store from '@/store';
    import flatPickr from 'vue-flatpickr-component';
    import { Spanish } from 'flatpickr/dist/l10n/es';
    import vSelect from 'vue-select';
    import Ripple from 'vue-ripple-directive';
    import useFormsList from './useFormsList';
    import formStoreModule from '../formStoreModule';
    import { v4 as uuidv4 } from 'uuid'
    import moment from 'moment';
    import axios from 'axios';

    export default {
        components: {       
            vSelect,
            flatPickr
        },
        directives: {
            'b-modal': VBModal,            
            'b-tooltip': VBTooltip,
            Ripple
        },
        data() {                              
            return {
                States,
                moment,
                dateNtim: null,
                formId: null,
                filter: null,
                config: {
                    enableTime: false, 
                    dateFormat: 'd-m-Y',
                    locale: Spanish
                },
                config2: {
                    enableTime: true, 
                    minuteIncrement: 1,
                    dateFormat: 'd-m-Y H:i K',
                    locale: Spanish
                }
            }
        },
        methods: {
            fileSrc (file){
                window.open(`${process.env.VUE_APP_FILES}/${file}?str=${uuidv4()}`, "_blank");
            },
            async downloadSupportJobCriticality (item){
                const response = await axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/form/${item.id}/support-job-criticality`);
                
                if (!response.data.count){
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: response.data.message,
                            icon: 'AlertTriangleIcon',
                            variant: 'danger'
                        }
                    });
                } else {
                    window.open(`${process.env.VUE_APP_FILES}/${response.data.file}?str=${uuidv4()}`, "_blank");
                }
            },
            async updateDateDispatchReview (date, id){
                this.dateNtim = null;
                this.dateNtim = date;
                this.formId = id;
                this.$refs['modal-date-dispatch-review'].show();
            },
            async onSubmit (){
                let newDateNtim = null;
                let dateNtim = null;
                let fullDateNtim = null;

                if (this.dateNtim){
                    if (!this.dateNtim.includes('T')){
                        dateNtim = this.dateNtim.split(' ');
                        fullDateNtim = dateNtim[0].split('-');
                        newDateNtim = `${fullDateNtim[2]}-${fullDateNtim[1]}-${fullDateNtim[0]} ${dateNtim[1]}:00`;
                    } else {
                        newDateNtim = moment(this.dateNtim).format("YYYY-MM-DD HH:mm:ss");
                    }
                }
                let response = await axios.put(`${process.env.VUE_APP_HOST}/api/dashboard/reviser/form/${this.formId}/date-dispatch-review/update`, {date_dispatch_review: newDateNtim});

                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: response.data.message,
                        icon: 'CheckIcon',
                        variant: 'success'
                    }
                });
                    
                this.$refs['refFormListTable'].refresh();
                this.$refs['modal-date-dispatch-review'].hide();
            },
            getButtonState (state_id) {
                let icon = 'ClockIcon', text = 'Revisión Pendiente', variant = 'dark'
                if (state_id === this.States.COMPLETADO) {
                    icon = 'CheckSquareIcon'
                    text = 'Revisión Terminada'
                    variant = 'success'
                }
                if (state_id === this.States.NO_REALIZADO) { 
                    icon = 'XCircleIcon'
                    text = 'Revisión Cancelada'
                }
                if (state_id === this.States.EN_REVISION) {
                    icon = 'EditIcon'
                    text = 'Revisar Formulario'
                    variant = 'info'
                }
                return { icon, text, variant }                
            },
            getDateFormat (date, subtract, dispatch) {
                if (!date) return ''
                let dateFormat = moment(date).format('DD-MM-YYYY') 
                if (subtract) dateFormat = moment(date).subtract(1, 'months').format('MM/YYYY') 
                if (dispatch) dateFormat = moment(date).format('DD-MM-YYYY HH:mm A')
               return dateFormat
            }            
        },
        setup() {
            
            const USER_APP_STORE_MODULE_NAME = 'app-reviser-form';

            // Register module
            if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, formStoreModule);

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME);
            });
               
            const optionsStates = reactive([]);            
            axios.get(`${process.env.VUE_APP_HOST}/api/dashboard/state/select`)
            .then(response => {
                 response.data.map( item => {
					optionsStates.push({
						label : item.name, 
						value : item.id
					});
				});
            });
            
            const {
                fetchForms,
                tableColumns,
                perPage,
                searchQuery,
                currentPage,
                totalUsers,
                dataMeta,
                perPageOptions,
                sortBy,
                isSortDirDesc,
                refFormListTable,
                refetchData,
                
                filterState,
                filterStartDate,
                filterEndDate
            } = useFormsList();

            return {
                searchQuery,
                fetchForms,
                tableColumns,
                perPage,
                currentPage,
                totalUsers,
                dataMeta,
                perPageOptions,
                sortBy,
                isSortDirDesc,
                refFormListTable,
                refetchData,       

                // Filter                
                optionsStates,
                filterState,
                filterStartDate,
                filterEndDate
            }
        }
    }

</script>

<style lang="scss" scoped>
    .per-page-selector {
        width: 90px;
    }    
</style>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>